<template>
  <div
      :class="containerClass"
      class="container-fluid"
  >
    <div v-if="stats" class="row">
      <div
          class="xl-40"
          :class="cols"
      >
        <card-icon
            :title="stats.payments.week / 100 + ' ' + currency"
            sub-title="views.dashboard.content.top.week-ca"
            iconFont="font-back-dark-blue"
            icon-class="text-white fa fa-3x fa-money"
        />
      </div>
      <div
          class="xl-40"
          :class="cols"
      >
        <card-icon
            :title="stats.bookings.week"
            sub-title="views.dashboard.content.top.week-booking"
            icon-font="font-back-red"
            icon-class="text-white fa fa-3x fa-calendar"
        />
      </div>
      <div
          class="xl-40"
          :class="cols"
      >
        <card-icon
            :title="stats.clients.week"
            sub-title="views.dashboard.content.top.week-new-users"
            icon-font="font-back-light-green"
            icon-class="text-white fa fa-3x fa-user"
        />
      </div>
      <div
          class="xl-40"
          :class="cols"
      >
        <card-icon
            :title="stats.clients.month"
            sub-title="views.dashboard.content.top.month-new-users"
            icon-font="font-back-yellow"
            icon-class="text-white fa fa-3x fa-users"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {getClubStats} from "@api/services/stats/stats.api";

export default {
  data: () => ({
    stats: null,
  }),
  props: {
    club: null,
    containerClass: {
      type: String,
      default: '',
    }
  },
  components: {
    CardIcon: () => import('@/components/custom/marketing/CardIcon')
  },
  computed: {
    currency() {
      if (this.club) {
        return this.$store.getters['currency/getCurrency'][this.club.currency];
      } else {
        return this.$store.getters['currency/getCurrency'][JSON.parse(localStorage.getItem('current-club')).currency];
      }
    },
    cols() {
      let innerWidth = this.$store.getters["layout/getInnerWidth"];

      if (innerWidth < 1300 && innerWidth > 876) {
        return 'col-xl-6 col-6';
      }
      if (innerWidth < 876) {
        return 'col-xl-12';
      }
      return 'col-xl-3';
    }
  },
  methods: {
    loadClubStats() {
      /*getClubStats(this.club.id, '', '').then((response) => {
        this.stats = response.data.stats;
      })*/
    }
  },
  created() {
    this.loadClubStats();
  }
}
</script>
